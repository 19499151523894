export const API_ADDRESS = process.env.REACT_APP_API_ADDRESS
export const SYNCER_ADDRESS = process.env.REACT_APP_SYNCER_ADDRESS
export const AZURE_REDIRECT_ADDRESS =
               process.env.REACT_APP_AZURE_REDIRECT_ADDRESS
export const REACT_APP_AZURE_CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID
export const REACT_APP_AZURE_AUTHORITY = process.env.REACT_APP_AZURE_AUTHORITY
export const REACT_APP_AZURE_API = process.env.REACT_APP_AZURE_API
export const REACT_APP_TOOLING_API = process.env.REACT_APP_TOOLING_API
export const REACT_APP_QA_API = process.env.REACT_APP_QA_API
export const RUNNING_DEV = process.env.NODE_ENV === 'development'
export const CURRENT_BUILD = process.env.REACT_APP_CURRENT_BUILD
export const LOCALE = process.env.REACT_APP_LOCALE
